import React from 'react';

const images = [
  '/image/products/VISUALS/VISUALS1.png',
  '/image/products/VISUALS/VISUALS2.png',
  '/image/products/VISUALS/VISUALS3.png',
  '/image/products/VISUALS/VISUALS4.png',
  '/image/products/VISUALS/VISUALS5.png',
  '/image/products/VISUALS/VISUALS6.png',
  '/image/products/VISUALS/VISUALS7.png',
  '/image/products/VISUALS/VISUALS8.png',
];

const Visual = () => {
  return (
    <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
    <h3 className="text-lg font-bold text-gray-900 mb-2">Visuals System</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
      {images.map((image, index) => (
        <div key={index}>
          <img className="h-auto max-w-full rounded-lg" src={image} alt={`VISUALS ${index + 1}`} />
        </div>
      ))}
    </div>
  </div>
  );
};

export default Visual;