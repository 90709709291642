import React from 'react';
import EventCard from './EventCard'; // Adjust the path to your actual file location

const Eventweproud = () => {
  // Define your event data (similar to the array in the previous response)
  const events = [
    {
      id: 1,
      title: "Celebrating the 85th anniversary of Schlumberger",
      imageUrl: "/image/EventWeProud/ev2.jpg",
      subTitle: "Largest oil company in the world",
    },{
      id:2, 
      title:"Gas investment conference for Basra Gas Company",
      imageUrl: "/image/EventWeProud/ev1.jpg",
      subTitle: "One of the largest gas flaring reduction",

    }
  ];

  return (
    <div className="container my-24 mx-auto md:px-6">
      <section className="mb-32 text-center lg:text-left">
        <h2 className="mb-12 text-center text-3xl font-bold">
          Events we are proud of
        </h2>
        <div className="grid gap-x-6 lg:grid-cols-3">
          {events.map((event) => (
            <EventCard
              key={event.id}
              title={event.title}
              imageUrl={event.imageUrl}
              subTitle={event.subTitle}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Eventweproud;
