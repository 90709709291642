import React, { useState, useEffect, useRef } from 'react';

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const selectedImageRef = useRef(null);

  const images = [
    './image/VIP/1.jpg',
    './image/VIP/2.jpg',
    './image/VIP/3.jpg',
    './image/VIP/4.jpg',
    './image/VIP/6.jpg',
    './image/VIP/7.jpg',
    './image/VIP/8.jpg',
    './image/VIP/9.jpg',
  ];

  const changeImage = (ind) => {
    setCurrentIndex(ind);
    resetInterval();
    scrollSelectedImageIntoView();
  };

  const resetInterval = () => {
    clearInterval(intervalId);
    const newIntervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    setIntervalId(newIntervalId);
  };

  const scrollSelectedImageIntoView = () => {
    if (selectedImageRef.current) {
      selectedImageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  useEffect(() => {
    resetInterval();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="grid gap-4">
      <div className="relative">
        <img
          className="w-auto rounded-lg mx-auto transition duration-300 transform"
          src={images[currentIndex]}
          alt="Selected Image"
          ref={selectedImageRef}
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        {images.map((image, index) => (
          <div key={index} className="hover:scale-110">
            <img
              className={`h-auto max-w-full rounded-lg cursor-pointer ${
                currentIndex === index && 'border-2 border-blue-500'
              }`}
              src={image}
              alt={`Image ${index + 1}`}
              onClick={() => {
                changeImage(index);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;