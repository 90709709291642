import React from 'react';
import { FaWhatsapp, FaEnvelope, FaPhone } from 'react-icons/fa';

const CompanyInterest = () => {
  

  return (
    <section className="my-4 h-[500px] h-[550px]">
      
     
      <div
        class="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] bg-[url('https://tecdn.b-cdn.net/img/new/textures/full/142.jpg')] h-[500px]">
      </div>
    
      <div class="w-100 mx-auto px-6 sm:max-w-2xl md:max-w-3xl md:px-12 lg:max-w-5xl xl:max-w-7xl xl:px-32">
        <div class="text-center">
          <div
            class="block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]   mt-[-350px] backdrop-blur-[30px]">
            <h1 class="m-2 text-2xl font-bold tracking-tight">
  
        We Take Pride In Being At The Forefront Of Technological Advancements.
    
            </h1>
           <p className='text-xl'>
           As the first company in Iraq to adopt artificial intelligence (AI) and business intelligence (BI) technology, we are committed to leveraging cutting-edge solutions in our  projects & Events .
           </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyInterest;
