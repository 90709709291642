import React from 'react';
import Team from '../../componants/Team';

const AddressComponent = ({ locationName, mapSrc, address, email, phone }) => {
    return (
      <div className="map-container">
        <h2 className="text-xl font-bold mx-4">{locationName}</h2>
        <p className="mx-4 mb-2">{address}</p>
        <p className="mx-4 mb-2">{phone}</p>
        <p className="mx-4 mb-2">{email}</p>
        <div className="w-full h-96">
          <iframe
            src={mapSrc}
            className="w-full h-full"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    );
  };


const ContactUs = () => {


  return (
    <div>
     <div className="text-center mx-auto py-12">
    <h1 className="text-4xl font-bold text-center mb-8 animate__animated animate__fadeInDown">Contact us </h1>
      </div>
     <Team/>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <AddressComponent
          locationName="Baghdad Location"
          address=" Iraq , Baghdad , Aljadriya ,Babylon Hotel Sq.          "
          phone="+964 7735909035 "
          email="info@al-aliaa.iq "
          mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13340.219980772374!2d44.3904889!3d33.2912242!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15577f8556cb046d%3A0xf25c159a99859720!2z2YHZhtiv2YIg2KjYp9io2YQ!5e0!3m2!1sar!2s!4v1711093754622!5m2!1sar!2s"
        />
        <AddressComponent
          locationName="Erbil Location"
          address="Iraq , Erbil
          Italian Village 1 , Villa No. 41"
          phone="+964 7735909035 "
          email="erbil@example.com"
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103043.66937764078!2d44.036982664326516!3d36.188092999862896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4007237b7b68efc5%3A0xbddc3c0ae7ef6a2f!2zSXRhbGlhbiBWaWxsYWdlLCDar9mI2YbYr9uMINim24zYqtin2rXbjA!5e0!3m2!1sar!2s!4v1711093894635!5m2!1sar!2s"
        />
      </div>
    </div>
  );
};

export default ContactUs;