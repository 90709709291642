import React from 'react';
import 'animate.css';

const Services = () => {
  const services = [
    {
      title: 'Hotel Reservation Worldwide',
      description: 'We offer hotel reservation services worldwide, ensuring that you find the perfect accommodation for your needs. Whether you are traveling for business or leisure, our extensive network of partner hotels allows us to provide you with a wide range of options at competitive rates.',
      image: '/image/Services/Hotel-Booking.jpg',
    },
    {
      title: 'Simultaneous Interpretation & Document Translation Services',
      description: 'Our team of professional interpreters provides simultaneous interpretation services in multiple languages for conferences, meetings, and events. Additionally, we offer document translation services to help you bridge language barriers in your business communications. We can handle a wide range of document types, ensuring accuracy and confidentiality.',
      image: '/image/Services/translate.jpg',
    },
    {
      title: 'Car Rental & Drivers',
      description: 'Need transportation during your stay? We offer convenient car rental services with a variety of vehicle options to suit your preferences. Whether you need a compact car for city exploration or a spacious SUV for a family trip, we have you covered. Our professional drivers are also available to ensure a comfortable and hassle-free experience.',
      image: '/image/Services/carRent.jpg',
    },
    {
      title: 'Food and Beverage outside hotels',
      description: 'Discover the culinary delights of your destination with our food and beverage services. We provide recommendations and arrangements for dining experiences outside of hotels, allowing you to savor local cuisine and immerse yourself in the culture. From popular restaurants to hidden gems, we can help you find the perfect dining options.',
      image: '/image/Services/food.jpg',
    },
    {
      title: 'Film Making; Personnel & Equipment, Supply Media Material',
      description: 'For film production needs, we offer comprehensive services that include personnel, equipment, and media material supply. Whether you are shooting a movie, documentary, or commercial, our experienced team can assist you in all aspects of production. From camera operators to editors, we have the expertise to bring your vision to life.',
      image: '/image/Services/FilmMakeing.png',
    },
    {
      title: 'Production, Branding & Visibility with professional designing services',
      description: 'Enhance your production, branding, and visibility with our professional designing services. Our talented designers can create visually appealing graphics, logos, and promotional materials that align with your brand identity. With our expertise, you can leave a lasting impression and effectively communicate your message to your target audience.',
      image: '/image/Services/Branding.jpg',
    },
    {
      title: 'Distributing DSA',
      description: 'Reach a wider audience and expand your distribution network with our Distribution Service Agreement (DSA). We can help you distribute your products or services to various locations, ensuring wider reach and increased sales opportunities. Our distribution network and logistics expertise ensure efficient and timely delivery.',
      image: '/image/Services/prudact.png',
    },
    {
      title: 'International & Domestic Air Reservations',
      description: 'Whether you are traveling internationally or domestically, we offer air reservation services to help you secure the best flight options. Our partnerships with major airlines enable us to provide competitive prices and convenient booking processes. We strive to make your travel experience seamless and stress-free.',
      image: '/image/Services/pass-1.png',
    },
    {
      title: 'Visa Services',
      description: 'Navigating the visa process can be complex and time-consuming. Our visa services aim to simplify the process and assist you in obtaining the necessary travel documents. Our experienced team can guide you through the requirements, documentation, and application procedures, ensuring a smooth visa application process.',
      image: '/image/Services/visa.jpg',
    },
    {
      title: 'Insurance Services',
      description: 'Protect yourself and your travel investments with our insurance services. We offer comprehensive travel insurance coverage that includes medical expenses, trip cancellation/interruption, baggage loss, and more. With our insurance services, you can have peace of mind knowing that you are financially protected during your travels.',
      image: '/image/Services/insurance.avif',
    },
  ];
  return (
    <div className="container mx-auto py-12">
    <h2 className="text-3xl font-bold text-center mb-8 animate__animated animate__fadeInDown">Our Services</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {services.map((service, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transform transition-all duration-300 hover:scale-105 animate__animated animate__fadeIn">
          <img src={service.image} alt={service.title} className="w-full h-48 object-cover rounded-t-lg" />
          <div className="p-6">
            <h3 className="text-xl font-bold mb-4">{service.title}</h3>
            <p className="text-gray-700">{service.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Services;