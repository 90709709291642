import React from 'react';
import { FaWhatsapp, FaEnvelope, FaPhone, FaAddressBook } from 'react-icons/fa';

const Member = ({ name, position, description, fb, phone, email, image }) => {
  const openWhatsAppChat = () => {
    window.open(`https://wa.me/${phone}`, '_blank');
  };

  const callthephone = () => {
    window.location.href = `tel:${phone}`;

  };

  const downloadVCard = () => {
    const vCard = `BEGIN:VCARD
VERSION:3.0
N:${name};;;;
FN:${name}
EMAIL;TYPE=work:${email}
TEL;TYPE=cell:${phone}
END:VCARD`;

    const vCardBlob = new Blob([vCard], { type: 'text/vcard' });
    const vCardURL = URL.createObjectURL(vCardBlob);

    const downloadLink = document.createElement('a');
    downloadLink.href = vCardURL;
    downloadLink.download = `${name}.vcf`;
    downloadLink.click();

    URL.revokeObjectURL(vCardURL);
  };

  return (
    <div className="flex items-center m-2">
      <img className="h-40 rounded mr-4" src={image} alt={name} />
      <div>
        <h4 className="text-lg font-medium">{name}</h4>
        <p className="text-gray-500">{position}</p>
        <a href={`mailto:${email}`} className="text-blue-500 hover:underline">
          {email}
        </a>
        <div className="mt-2">
          <button
            onClick={openWhatsAppChat}
            className="bg-green-500 m-1 text-white p-3 rounded-full mr-2 hover:bg-green-600"
          >
            <FaWhatsapp className="text-xl " /> 
          </button>
          <button
            onClick={() => window.location.href = `mailto:${email}`}
            className="bg-blue-500 m-1 text-white p-3 rounded-full hover:bg-blue-600"
          >
            <FaEnvelope className="text-xl" /> 
          </button>
          <button
            onClick={callthephone}
            className="bg-blue-500 m-1 text-white p-3 rounded-full mr-2 hover:bg-blue-600"
          >
            <FaPhone className="text-xl " />
          </button>
          <button
            onClick={downloadVCard}
            className="bg-yellow-500 m-1 text-white p-3 rounded-full hover:bg-yellow-600"
          >
            <FaAddressBook className="text-xl " />  
          </button>
        </div>
      </div>
    </div>
  );
};

export default Member;