import { createBrowserRouter } from "react-router-dom";
import App from "../App" ;
import Home from "../pages/home/Home";
import Services from "../pages/Services/Services";
import About from "../pages/About/About";
import Events from "../pages/Events/Events";
import ContactUs from "../pages/ContactUs/ContactUs";
import ProductList from "../pages/Products/ProductList";
import Translate from "../pages/Products/Translate";
import Film from "../pages/Products/Film";
import Visual from "../pages/Products/Visual";
import Print from "../pages/Products/Print";
import Audio from "../pages/Products/Audio";
const Router = createBrowserRouter([
    {
        path:"/", 
        element: < App/> ,
        children:[
            {
                path:"/",
                element: <Home/>
            }, 
            {
                path:"/home",
                element: <Home/>
            },
             
            {
                path:"/Services",
                element: <Services/>
            },
            {
                path:"/About",
                element: <About/>
            },
            
            {
                path:"/products",
                element: <ProductList/>, 
                children:[{
                    path:"/products/" , 
                    element:<Translate/>
                },{
                    path:"/products/Translation" , 
                    element:<Translate/>
                },{
                    path:"/products/Audio" , 
                    element:<Audio/>
                },{
                    path:"/products/Film" , 
                    element:<Film/>
                },{
                    path:"/products/Visual" , 
                    element:<Visual/>
                }, {
                    path:"/products/Branding" , 
                    element:<Print/>
                },]
            },
            {
                path:"/Event-history",
                element: <Events/>
            },
            ,
            {
                path:"/Contact",
                element: <ContactUs/>
            },
        ]
        }
]);
export default Router ; 