import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FaTeamspeak, FaVoicemail, FaVideo, FaCamera, FaPrint } from 'react-icons/fa';

const TabBar = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('Translation');

  useEffect(() => {
    setCurrentPath(location.pathname.split('/').pop());
  }, [location.pathname]);

  return (
    <div className="grid grid-cols-12 text-cetner ">
      <ul className="sticky top-10 col-span-12 flex flex-wrap justify-center bg-white items-center rounded-lg pt-5 pb-2">
        <li>
          <Link
            to="./Translation"
            className={`inline-flex text-sm items-center px-4 py-3 rounded-lg ${
              currentPath === 'Translation' || currentPath === 'products'
                ? 'text-white bg-teal-800'
                : 'text-gray-900 bg-gray-50 hover:bg-gray-100'
            }`}
            aria-current="page"
          >
            <FaTeamspeak className="mr-1 text-lg" />
            TRANSLATE
          </Link>
        </li>
        <li>
          <Link
            to="./Audio"
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentPath === 'Audio'
                ? 'text-white bg-teal-800'
                : 'text-gray-900 bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <FaVoicemail className="mr-1" />
            AUDIO
          </Link>
        </li>
        <li>
          <Link
            to="./Visual"
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentPath === 'Visual'
                ? 'text-white bg-teal-800'
                : 'text-gray-900 bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <FaVideo className="mr-1" />
            VISUALS
          </Link>
        </li>
        <li>
          <Link
            to="./Film"
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentPath === 'Film'
                ? 'text-white bg-teal-800'
                : 'text-gray-900 bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <FaCamera className="mr-1" />
            Filming
          </Link>
        </li>
        <li>
          <Link
            to="./branding"
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentPath === 'branding'
                ? 'text-white bg-teal-800'
                : 'text-gray-900 bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <FaPrint className="mr-1" />
            branding
          </Link>
        </li>
      </ul>

      <div className="col-span-12 "></div>

      <div className="col-span-12 ">
        <Outlet />
      </div>
    </div>
  );
};

export default TabBar;