import React from 'react';
import 'animate.css';

const ClientSection = () => {
  const clientLogos = [
    { logo: 'npaid.png', name: 'NPAID' },
    { logo: 'The_World_Bank.png', name: 'The World Bank' },
    { logo: 'UNICEF.png', name: 'UNICEF' },
    { logo: 'UNODC-256.png', name: 'UNODC' },
    { logo: 'MIE.jpg', name: ' ' },
    { logo: 'ILO.png', name: 'I L O' }, 
    { logo: 'UNDP.png', name: 'UNDP' }, 
    { logo: 'CBI.png', name: 'CBI' }, 
    { logo: 'ICMPD.png', name: 'ICMPD' }, 
    { logo: 'ITC.png', name: 'ITC' }, 
    { logo: 'MNLL.png', name: 'MNLL' }, 
    { logo: 'MOI.png', name: 'MOI' }, 
    { logo: 'MOLASA.png', name: 'MOLASA' }, 
    { logo: 'NDI.png', name: 'NDI' }, 
    { logo: 'SJC.png', name: 'SJC' }, 

    { logo: 'SLB.png', name: 'SLB' }, 


  ];

  return (
    <section className='my-8'>
      <h2 className="text-3xl font-extrabold text-gray-900 text-center my-6 ">Al-aliaa Clients</h2>

      <div className="relative m-auto mt-2 w-90 overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(250px*10)]">
          {clientLogos.map((client, index) => (
            <div key={index} className="flex flex-col items-center">
              <img
                src={`image/Clients/${client.logo}`}
                className="slide flex w-[125px] items-center mx-4 justify-center"
                alt={`Client Logo ${index + 1}`}
              />
              <strong className="mt-2 font-bold">{client.name}</strong>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientSection;