import React, { useRef, useEffect } from 'react';

const VideoContent = ({ title, videoPath }) => {
  const videoRef = useRef(null);


  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <div className="aspect-w-16 aspect-h-9">
        <video ref={videoRef} controls className="w-full h-full rounded-lg" loading="lazy">
          <source src={videoPath} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoContent;
