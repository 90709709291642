import React from 'react';
import TabBar from './TabBar';

const ProductList = () => {
 

  return (
<section> 


    <div className="flex flex-wrap justify-center my-2 mx-2">
    <TabBar/>
     
    </div>
    </section>
  );
};

export default ProductList;