import React from 'react';
import 'animate.css';
import PortfolioSection from './Sections/PortfolioSection';

const WelcomePage = () => {
  return (
    <section className="bg-gray-100 ">
      <div className="container text-center mx-auto p-10">
      
<h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl animate__animated animate__fadeInDown ">Welecom to Al-Aliaa Group</h1>

    <p>
    Al-Aliaa Company For Travel, Tourism and Organization of International Conferences

    </p>
      </div>
    </section>
  );
};

export default WelcomePage;