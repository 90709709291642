import React from 'react';

const PortfolioSection = () => {
  return (
    <section className="py-8">
     <div className="flex justify-center">
        <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold mb-4"></h2>
      
        <div className="flex justify-center">
          <a
            href="/Aliaa.pdf"  // Replace with the actual path to your portfolio file
            className="bg-teal-800 hover:bg-teal-600 text-white font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline"
            download
          >
            Download Our Portfolio
          </a>
        </div>
      </div>
        </div>
    </section>
  );
};

export default PortfolioSection;