import React from 'react';

const images = [
  '/image/products/TRANSLATION/translation1.png',
  '/image/products/TRANSLATION/translation2.png',
  '/image/products/TRANSLATION/translation3.png',
  '/image/products/TRANSLATION/translation4.png',
  '/image/products/TRANSLATION/translation5.png',
  '/image/products/TRANSLATION/translation6.png',
];

const Translate = () => {
  return (
    <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
      <h3 className="text-lg font-bold text-gray-900 mb-2">Translation System</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {images.map((image, index) => (
          <div key={index}>
            <img className="h-auto max-w-full rounded-lg" src={image} alt={`Audio ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Translate;