import React, { useState, useEffect } from 'react';
import 'animate.css';

const About = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    // Trigger animation when the component mounts
    setIsAnimated(true);
  }, []);

  return (
    <div className={`sm:flex items-center max-w-screen-xl ${isAnimated ? 'animate__animated animate__fadeInUp' : ''}`}>
      <div className="sm:w-1/2 p-10 animate__animated animate__slideInLeft">
        <div className="image object-center text-center">
          <img src="https://i.imgur.com/WbQnbas.png" alt="About Us" />
        </div>
      </div>
      <div className="sm:w-1/2 p-5">
        <div className="text">
          <h2 className={`my-4 font-bold text-3xl sm:text-4xl ${isAnimated ? 'animate__animated animate__fadeInUp' : ''}`}>
            About <span className="text-teal-800">Al-Aliaa Group </span>
          </h2>
          <p className={`text-lg mt-4 ${isAnimated ? 'animate__animated animate__fadeInUp' : ''}`}>
            Al-Aliaa Company is a proactive leader in the airline reservation industry, serving clients in Iraq, the Gulf States, and the Arab World since 1997. With an official license granted by the Iraqi Ministry of Tourism (License No. 196), we proudly represent esteemed airlines including Iraqi Airways, MEA Airlines, Emirates Airlines, and more.
          </p>
          <p className={`text-lg mt-4 ${isAnimated ? 'animate__animated animate__fadeInUp' : ''}`}>
            Our commitment to excellence extends beyond ticketing services. We specialize in international conference management, offering top-tier hospitality, simultaneous interpretation, and event planning services. From UN agencies to governmental bodies, we ensure seamless execution of events in all Iraqi provinces.
          </p>
          <p className={`text-lg mt-4 ${isAnimated ? 'animate__animated animate__fadeInUp' : ''}`}>
            At Al-Aliaa, quality is paramount. We have achieved ISO 9001 Standards for service excellence and are actively pursuing ISO 14001 environmental certification, underscoring our dedication to delivering the highest level of service while minimizing our environmental impact.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;