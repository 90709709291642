import React from 'react';
import WelcomePage from '../../componants/WelcomePage';
import Eventweproud from '../../componants/Eventweproud';
import Sliders from '../../componants/Carousel/Sliders';
import Clients from '../../componants/Clients/Clients';
import Gallery from '../../componants/Sections/Gallery';
import PortfolioSection from '../../componants/Sections/PortfolioSection';
import VideoContent from '../../componants/Sections/VideoContent';
import OurVision from '../../componants/Sections/OurVision';
import CompanyInterest from '../../componants/CompanyInterest';

const Home = () => {
  return (
    <div className='blocks'>
      <div>
      <WelcomePage/>

  <Gallery/>
  <CompanyInterest/>
  <Clients/>

  <OurVision/>

  <Eventweproud/> 

  <VideoContent title ="Al-aleaa Demo " videoPath="/Vedio/Vedio.mp4" />
      </div>
    </div>
  );
};

export default Home;