import React from 'react';
import Member from './Member';

const teamMembers = [
  {
    name: 'Mohammed Aljdah',
    position: 'CEO',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    fb: 'https://example.com/facebook',
    phone: '+9647716091990',
    email: 'Mohammed@al-aliaa.iq',
    image: './image/Team/Mohammed Aljaddah.jpeg'
  },
  {
    name: 'Nazih Daryousi',
    position: 'Director of sales and business development',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    fb: 'https://example.com/facebook',
    x: 'https://example.com/twitter',
    email: 'nazih.daryousi@al-aliaa.iq',
    phone:"+9647748118998",
    image: './image/Team/Nazih Daryousi.JPG'
  },
  // Add more team members here
];

const Team = () => {
  return (
    <section className="bg-whit">
      <div className=" mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      
        <div className="grid gap-4 mb-6 lg:mb-16 md:grid-cols-2">
          {teamMembers.map((member, index) => (
            <Member
              key={index}
              name={member.name}
              position={member.position}
              Desccription={member.description}
              fb={member.fb}
              phone={member.phone}
              email={member.email}
              image={member.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;